// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-404-pt-mdx": () => import("./../../../src/pages/404.pt.mdx" /* webpackChunkName: "component---src-pages-404-pt-mdx" */),
  "component---src-pages-about-index-mdx": () => import("./../../../src/pages/about/index.mdx" /* webpackChunkName: "component---src-pages-about-index-mdx" */),
  "component---src-pages-about-index-pt-mdx": () => import("./../../../src/pages/about/index.pt.mdx" /* webpackChunkName: "component---src-pages-about-index-pt-mdx" */),
  "component---src-pages-components-accordion-index-mdx": () => import("./../../../src/pages/components/accordion/index.mdx" /* webpackChunkName: "component---src-pages-components-accordion-index-mdx" */),
  "component---src-pages-components-accordion-index-pt-mdx": () => import("./../../../src/pages/components/accordion/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-accordion-index-pt-mdx" */),
  "component---src-pages-components-alert-index-mdx": () => import("./../../../src/pages/components/alert/index.mdx" /* webpackChunkName: "component---src-pages-components-alert-index-mdx" */),
  "component---src-pages-components-alert-index-pt-mdx": () => import("./../../../src/pages/components/alert/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-alert-index-pt-mdx" */),
  "component---src-pages-components-breadcrumb-index-mdx": () => import("./../../../src/pages/components/breadcrumb/index.mdx" /* webpackChunkName: "component---src-pages-components-breadcrumb-index-mdx" */),
  "component---src-pages-components-breadcrumb-index-pt-mdx": () => import("./../../../src/pages/components/breadcrumb/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-breadcrumb-index-pt-mdx" */),
  "component---src-pages-components-button-index-mdx": () => import("./../../../src/pages/components/button/index.mdx" /* webpackChunkName: "component---src-pages-components-button-index-mdx" */),
  "component---src-pages-components-button-index-pt-mdx": () => import("./../../../src/pages/components/button/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-button-index-pt-mdx" */),
  "component---src-pages-components-cards-index-mdx": () => import("./../../../src/pages/components/cards/index.mdx" /* webpackChunkName: "component---src-pages-components-cards-index-mdx" */),
  "component---src-pages-components-cards-index-pt-mdx": () => import("./../../../src/pages/components/cards/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-cards-index-pt-mdx" */),
  "component---src-pages-components-checkbox-index-mdx": () => import("./../../../src/pages/components/checkbox/index.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-index-mdx" */),
  "component---src-pages-components-checkbox-index-pt-mdx": () => import("./../../../src/pages/components/checkbox/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-index-pt-mdx" */),
  "component---src-pages-components-content-switch-index-mdx": () => import("./../../../src/pages/components/content-switch/index.mdx" /* webpackChunkName: "component---src-pages-components-content-switch-index-mdx" */),
  "component---src-pages-components-content-switch-index-pt-mdx": () => import("./../../../src/pages/components/content-switch/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-content-switch-index-pt-mdx" */),
  "component---src-pages-components-date-picker-index-mdx": () => import("./../../../src/pages/components/date-picker/index.mdx" /* webpackChunkName: "component---src-pages-components-date-picker-index-mdx" */),
  "component---src-pages-components-date-picker-index-pt-mdx": () => import("./../../../src/pages/components/date-picker/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-date-picker-index-pt-mdx" */),
  "component---src-pages-components-dropdown-index-mdx": () => import("./../../../src/pages/components/dropdown/index.mdx" /* webpackChunkName: "component---src-pages-components-dropdown-index-mdx" */),
  "component---src-pages-components-dropdown-index-pt-mdx": () => import("./../../../src/pages/components/dropdown/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-dropdown-index-pt-mdx" */),
  "component---src-pages-components-file-uploader-index-mdx": () => import("./../../../src/pages/components/file-uploader/index.mdx" /* webpackChunkName: "component---src-pages-components-file-uploader-index-mdx" */),
  "component---src-pages-components-file-uploader-index-pt-mdx": () => import("./../../../src/pages/components/file-uploader/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-file-uploader-index-pt-mdx" */),
  "component---src-pages-components-form-index-mdx": () => import("./../../../src/pages/components/form/index.mdx" /* webpackChunkName: "component---src-pages-components-form-index-mdx" */),
  "component---src-pages-components-form-index-pt-mdx": () => import("./../../../src/pages/components/form/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-form-index-pt-mdx" */),
  "component---src-pages-components-link-index-mdx": () => import("./../../../src/pages/components/link/index.mdx" /* webpackChunkName: "component---src-pages-components-link-index-mdx" */),
  "component---src-pages-components-link-index-pt-mdx": () => import("./../../../src/pages/components/link/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-link-index-pt-mdx" */),
  "component---src-pages-components-modal-index-mdx": () => import("./../../../src/pages/components/modal/index.mdx" /* webpackChunkName: "component---src-pages-components-modal-index-mdx" */),
  "component---src-pages-components-modal-index-pt-mdx": () => import("./../../../src/pages/components/modal/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-modal-index-pt-mdx" */),
  "component---src-pages-components-month-paginator-index-mdx": () => import("./../../../src/pages/components/month-paginator/index.mdx" /* webpackChunkName: "component---src-pages-components-month-paginator-index-mdx" */),
  "component---src-pages-components-month-paginator-index-pt-mdx": () => import("./../../../src/pages/components/month-paginator/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-month-paginator-index-pt-mdx" */),
  "component---src-pages-components-multi-select-index-mdx": () => import("./../../../src/pages/components/multi-select/index.mdx" /* webpackChunkName: "component---src-pages-components-multi-select-index-mdx" */),
  "component---src-pages-components-multi-select-index-pt-mdx": () => import("./../../../src/pages/components/multi-select/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-multi-select-index-pt-mdx" */),
  "component---src-pages-components-overview-index-mdx": () => import("./../../../src/pages/components/overview/index.mdx" /* webpackChunkName: "component---src-pages-components-overview-index-mdx" */),
  "component---src-pages-components-overview-index-pt-mdx": () => import("./../../../src/pages/components/overview/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-overview-index-pt-mdx" */),
  "component---src-pages-components-paginator-index-mdx": () => import("./../../../src/pages/components/paginator/index.mdx" /* webpackChunkName: "component---src-pages-components-paginator-index-mdx" */),
  "component---src-pages-components-paginator-index-pt-mdx": () => import("./../../../src/pages/components/paginator/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-paginator-index-pt-mdx" */),
  "component---src-pages-components-radio-button-index-mdx": () => import("./../../../src/pages/components/radio-button/index.mdx" /* webpackChunkName: "component---src-pages-components-radio-button-index-mdx" */),
  "component---src-pages-components-radio-button-index-pt-mdx": () => import("./../../../src/pages/components/radio-button/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-radio-button-index-pt-mdx" */),
  "component---src-pages-components-search-index-mdx": () => import("./../../../src/pages/components/search/index.mdx" /* webpackChunkName: "component---src-pages-components-search-index-mdx" */),
  "component---src-pages-components-search-index-pt-mdx": () => import("./../../../src/pages/components/search/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-search-index-pt-mdx" */),
  "component---src-pages-components-select-index-mdx": () => import("./../../../src/pages/components/select/index.mdx" /* webpackChunkName: "component---src-pages-components-select-index-mdx" */),
  "component---src-pages-components-select-index-pt-mdx": () => import("./../../../src/pages/components/select/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-select-index-pt-mdx" */),
  "component---src-pages-components-stepper-index-mdx": () => import("./../../../src/pages/components/stepper/index.mdx" /* webpackChunkName: "component---src-pages-components-stepper-index-mdx" */),
  "component---src-pages-components-stepper-index-pt-mdx": () => import("./../../../src/pages/components/stepper/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-stepper-index-pt-mdx" */),
  "component---src-pages-components-switch-index-mdx": () => import("./../../../src/pages/components/switch/index.mdx" /* webpackChunkName: "component---src-pages-components-switch-index-mdx" */),
  "component---src-pages-components-switch-index-pt-mdx": () => import("./../../../src/pages/components/switch/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-switch-index-pt-mdx" */),
  "component---src-pages-components-table-index-mdx": () => import("./../../../src/pages/components/table/index.mdx" /* webpackChunkName: "component---src-pages-components-table-index-mdx" */),
  "component---src-pages-components-table-index-pt-mdx": () => import("./../../../src/pages/components/table/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-table-index-pt-mdx" */),
  "component---src-pages-components-tabs-index-mdx": () => import("./../../../src/pages/components/tabs/index.mdx" /* webpackChunkName: "component---src-pages-components-tabs-index-mdx" */),
  "component---src-pages-components-tabs-index-pt-mdx": () => import("./../../../src/pages/components/tabs/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-tabs-index-pt-mdx" */),
  "component---src-pages-components-tag-index-mdx": () => import("./../../../src/pages/components/tag/index.mdx" /* webpackChunkName: "component---src-pages-components-tag-index-mdx" */),
  "component---src-pages-components-tag-index-pt-mdx": () => import("./../../../src/pages/components/tag/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-tag-index-pt-mdx" */),
  "component---src-pages-components-text-field-index-mdx": () => import("./../../../src/pages/components/text-field/index.mdx" /* webpackChunkName: "component---src-pages-components-text-field-index-mdx" */),
  "component---src-pages-components-text-field-index-pt-mdx": () => import("./../../../src/pages/components/text-field/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-text-field-index-pt-mdx" */),
  "component---src-pages-components-toast-index-mdx": () => import("./../../../src/pages/components/toast/index.mdx" /* webpackChunkName: "component---src-pages-components-toast-index-mdx" */),
  "component---src-pages-components-toast-index-pt-mdx": () => import("./../../../src/pages/components/toast/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-toast-index-pt-mdx" */),
  "component---src-pages-components-tooltip-index-mdx": () => import("./../../../src/pages/components/tooltip/index.mdx" /* webpackChunkName: "component---src-pages-components-tooltip-index-mdx" */),
  "component---src-pages-components-tooltip-index-pt-mdx": () => import("./../../../src/pages/components/tooltip/index.pt.mdx" /* webpackChunkName: "component---src-pages-components-tooltip-index-pt-mdx" */),
  "component---src-pages-design-guidelines-accessibility-index-mdx": () => import("./../../../src/pages/design-guidelines/accessibility/index.mdx" /* webpackChunkName: "component---src-pages-design-guidelines-accessibility-index-mdx" */),
  "component---src-pages-design-guidelines-accessibility-index-pt-mdx": () => import("./../../../src/pages/design-guidelines/accessibility/index.pt.mdx" /* webpackChunkName: "component---src-pages-design-guidelines-accessibility-index-pt-mdx" */),
  "component---src-pages-design-guidelines-color-index-mdx": () => import("./../../../src/pages/design-guidelines/color/index.mdx" /* webpackChunkName: "component---src-pages-design-guidelines-color-index-mdx" */),
  "component---src-pages-design-guidelines-color-index-pt-mdx": () => import("./../../../src/pages/design-guidelines/color/index.pt.mdx" /* webpackChunkName: "component---src-pages-design-guidelines-color-index-pt-mdx" */),
  "component---src-pages-design-guidelines-color-usage-mdx": () => import("./../../../src/pages/design-guidelines/color/usage.mdx" /* webpackChunkName: "component---src-pages-design-guidelines-color-usage-mdx" */),
  "component---src-pages-design-guidelines-color-usage-pt-mdx": () => import("./../../../src/pages/design-guidelines/color/usage.pt.mdx" /* webpackChunkName: "component---src-pages-design-guidelines-color-usage-pt-mdx" */),
  "component---src-pages-design-guidelines-data-visualization-index-mdx": () => import("./../../../src/pages/design-guidelines/data-visualization/index.mdx" /* webpackChunkName: "component---src-pages-design-guidelines-data-visualization-index-mdx" */),
  "component---src-pages-design-guidelines-data-visualization-index-pt-mdx": () => import("./../../../src/pages/design-guidelines/data-visualization/index.pt.mdx" /* webpackChunkName: "component---src-pages-design-guidelines-data-visualization-index-pt-mdx" */),
  "component---src-pages-design-guidelines-data-visualization-models-mdx": () => import("./../../../src/pages/design-guidelines/data-visualization/models.mdx" /* webpackChunkName: "component---src-pages-design-guidelines-data-visualization-models-mdx" */),
  "component---src-pages-design-guidelines-data-visualization-models-pt-mdx": () => import("./../../../src/pages/design-guidelines/data-visualization/models.pt.mdx" /* webpackChunkName: "component---src-pages-design-guidelines-data-visualization-models-pt-mdx" */),
  "component---src-pages-design-guidelines-data-visualization-usage-mdx": () => import("./../../../src/pages/design-guidelines/data-visualization/usage.mdx" /* webpackChunkName: "component---src-pages-design-guidelines-data-visualization-usage-mdx" */),
  "component---src-pages-design-guidelines-data-visualization-usage-pt-mdx": () => import("./../../../src/pages/design-guidelines/data-visualization/usage.pt.mdx" /* webpackChunkName: "component---src-pages-design-guidelines-data-visualization-usage-pt-mdx" */),
  "component---src-pages-design-guidelines-grid-index-mdx": () => import("./../../../src/pages/design-guidelines/grid/index.mdx" /* webpackChunkName: "component---src-pages-design-guidelines-grid-index-mdx" */),
  "component---src-pages-design-guidelines-grid-index-pt-mdx": () => import("./../../../src/pages/design-guidelines/grid/index.pt.mdx" /* webpackChunkName: "component---src-pages-design-guidelines-grid-index-pt-mdx" */),
  "component---src-pages-design-guidelines-iconography-index-mdx": () => import("./../../../src/pages/design-guidelines/iconography/index.mdx" /* webpackChunkName: "component---src-pages-design-guidelines-iconography-index-mdx" */),
  "component---src-pages-design-guidelines-iconography-index-pt-mdx": () => import("./../../../src/pages/design-guidelines/iconography/index.pt.mdx" /* webpackChunkName: "component---src-pages-design-guidelines-iconography-index-pt-mdx" */),
  "component---src-pages-design-guidelines-iconography-library-mdx": () => import("./../../../src/pages/design-guidelines/iconography/library.mdx" /* webpackChunkName: "component---src-pages-design-guidelines-iconography-library-mdx" */),
  "component---src-pages-design-guidelines-iconography-library-pt-mdx": () => import("./../../../src/pages/design-guidelines/iconography/library.pt.mdx" /* webpackChunkName: "component---src-pages-design-guidelines-iconography-library-pt-mdx" */),
  "component---src-pages-design-guidelines-typography-index-mdx": () => import("./../../../src/pages/design-guidelines/typography/index.mdx" /* webpackChunkName: "component---src-pages-design-guidelines-typography-index-mdx" */),
  "component---src-pages-design-guidelines-typography-index-pt-mdx": () => import("./../../../src/pages/design-guidelines/typography/index.pt.mdx" /* webpackChunkName: "component---src-pages-design-guidelines-typography-index-pt-mdx" */),
  "component---src-pages-getting-started-index-mdx": () => import("./../../../src/pages/getting-started/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-index-mdx" */),
  "component---src-pages-getting-started-index-pt-mdx": () => import("./../../../src/pages/getting-started/index.pt.mdx" /* webpackChunkName: "component---src-pages-getting-started-index-pt-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-usage-i-18-n-index-mdx": () => import("./../../../src/pages/usage/i18n/index.mdx" /* webpackChunkName: "component---src-pages-usage-i-18-n-index-mdx" */),
  "component---src-pages-usage-i-18-n-index-pt-mdx": () => import("./../../../src/pages/usage/i18n/index.pt.mdx" /* webpackChunkName: "component---src-pages-usage-i-18-n-index-pt-mdx" */),
  "component---src-pages-usage-styles-index-mdx": () => import("./../../../src/pages/usage/styles/index.mdx" /* webpackChunkName: "component---src-pages-usage-styles-index-mdx" */),
  "component---src-pages-usage-styles-index-pt-mdx": () => import("./../../../src/pages/usage/styles/index.pt.mdx" /* webpackChunkName: "component---src-pages-usage-styles-index-pt-mdx" */),
  "component---src-pages-usage-theme-index-mdx": () => import("./../../../src/pages/usage/theme/index.mdx" /* webpackChunkName: "component---src-pages-usage-theme-index-mdx" */),
  "component---src-pages-usage-theme-index-pt-mdx": () => import("./../../../src/pages/usage/theme/index.pt.mdx" /* webpackChunkName: "component---src-pages-usage-theme-index-pt-mdx" */)
}

